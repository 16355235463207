import React from 'react';
import { Text, Box } from '@radix-ui/themes';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Inside = () => {

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      };
    

    return <>
    
        <Box className="bg-secondary" id="feature">
            <Box style={{
                width: "100%",
                marginTop: '30px',
                textAlign: "center"
            }}>
                <Text size={"7"} weight={"bold"}>Qu’y a-t-il dans mon Koffy ?</Text>
            </Box>

            {/** Sucre dans le koffy */}
            <Box className="md:grid md:grid-cols-2" style={{
                width: '100%',
                display: 'flex',
            }}>

                    <Box className="flex items-start font-montserrat mr-10" style={{
                        alignItems: "center",
                        width: "48%"
                    }}>
                        <Box className="h-5 mr-4">
                            <i className="fa-solid fa-mug-hot" ></i>
                        </Box>
                        <Box>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Text className="font-semibold font-pt-serif text-2xl mb-5">Un peu de sucre avec votre KOFFY ?</Text>
                        <br />
                            <Text>
                                Chaque jour, retrouvez un résumé de l’actualité, l’état des marchés, une analyse sentimentale des réseaux sociaux, 
                                offres d’emploi et tendances NFT. Nous améliorons constamment Koffy en ajoutant de nouvelles fonctionnalités, que vous (les lecteurs), nous suggérez !
                            </Text>
                        <br />
                        </Box>
                    </Box>

                    <Box className="flex items-start font-montserrat mr-10" style={{
                            width: '45%',
                        }}>
                        <Box style={{
                            marginRight: '20px',
                            marginLeft: '20px'
                        }}>
                            <img className="rounded-md object-cover object-center rounded" alt="hero" src="/imgs/theme/main_01.png" />
                        </Box>
                    </Box>

            </Box>


            {/** Vos besoins en news */}
            <Box className="md:grid md:grid-cols-2" style={{
                width: '100%',
                display: 'flex',
            }}>

                    <Box className="flex items-start font-montserrat mr-10" style={{
                            width: '50%',
                        }}>
                        <Box style={{
                            marginRight: '20px',
                            marginLeft: '20px'
                        }}>
                            <img className="rounded-md object-cover object-center rounded" alt="hero" src="/imgs/theme/main_02.png" />
                        </Box>
                    </Box>

                    <Box className="flex items-start font-montserrat mr-10" style={{
                        alignItems: "center",
                        width: "48%"
                    }}>
                        <Box className="h-5 mr-4">
                            <i className="fa-solid fa-filter"></i>
                        </Box>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Box>
                            <h3 className="font-semibold font-pt-serif text-2xl mb-5">Vos besoins, nos actualités : My KoFeed</h3>
                            <Text>
                            En plus des trois à six articles d’actualité résumé par notre Intelligence Artificielle, nous vous permettons de définir jusqu’à 10 mots clés dans vos paramètres, 
                            qui seront utilisés pour vous montrer les titres d’actualité pertinents (et un lien vers le partenaire média correspondant) 
                            afin d’améliorer la surveillance des médias par rapport à vos intérêts !
                            </Text>
                        </Box>
                    </Box>

            </Box>


            {/** Les partenaires */}
            <Box style={{
                textAlign: "center",
                marginTop: '40px'
            }}>
                <Text size={"7"} weight={"bold"}>Nos médias partenaires:</Text>
            </Box>


            <Box style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Slider {...sliderSettings} style={{marginTop: '40px', width: "90%"}}>
                    {/** La galerie photo */}
                            <Box className="flex items-center font-montserrat">
                                <a href="https://journalducoin.com/"><img alt="company" width="100px" src="/imgs/partners/logo_jdc.svg"/></a>
                            </Box>
                            <Box className="flex items-center font-montserrat">
                                <a href="https://cryptoast.fr/"><img alt="company" width="160px" src="https://cryptoast.fr/wp-content/uploads/2022/06/cryptoast-logo-amp.png"/></a>
                            </Box>
                            <Box className="flex items-center font-montserrat">
                                <a href="https://fr.beincrypto.com/"><img alt="company" width="100px" src="/imgs/partners/logo_beincrypto.svg"/></a>
                            </Box>
                            <Box className="flex items-center font-montserrat">
                                <a href="https://cointribune.com/"><img alt="company" width="150px" src="/imgs/partners/logo_ct.svg"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://fr.cointelegraph.com"><img alt="company" width="150px" src="/imgs/partners/logo_cte.svg"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://coinactucrypto.com/"><img alt="company" width="150px" src="/imgs/partners/logo_coinactucrypto.png"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://cryptonomist.ch/"><img alt="company" width="150px" src="/imgs/partners/logo_cryptonomist.svg"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://aucoindubloc.com/"><img alt="company" width="150px" src="/imgs/partners/logo_aucoindubloc.svg"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://coinacademy.fr/"><img alt="company" width="150px" src="/imgs/partners/logo_coinacademy.svg"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://coinaute.com/"><img alt="company" width="150px" src="/imgs/partners/logo_coinaute.png"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://onchainjobs.io/"><img alt="company" width="150px" src="/imgs/partners/logo_onchain.svg"/></a>
                            </Box>
                            <Box className="flex items-start font-montserrat">
                                <a href="https://oakinvest.fr"><img alt="company" width="150px" src="/imgs/partners/oak.png"/></a>
                            </Box>
                </Slider>
            </Box>
        </Box>
    </>
}

export default Inside